import { CdkTrapFocus } from '@angular/cdk/a11y';
import { Component, computed, inject } from '@angular/core';
import { UtilityService } from '@shared/services/utility.service';
import { Tab } from '@shared/ui/tabs/tab/tab.component';
import { TabsComponent } from '@shared/ui/tabs/tabs.component';
import { AuthService } from '@auth/services/auth.service';
import { ButtonComponent } from '../../shared/ui/button/button.component';

@Component({
  selector: 'coach-navigation',
  standalone: true,
  imports: [TabsComponent, ButtonComponent, CdkTrapFocus],
  templateUrl: './navigation.component.html',
  styleUrl: './navigation.component.scss',
  host: {
    '[class.mobile]': 'utility.navigation.isOverlay()',
    '[class.expanded]': 'utility.navigation.toggled()',
  },
})
export class NavigationComponent {
  #auth = inject(AuthService);
  utility = inject(UtilityService);

  tabs = computed<Tab[]>(() => [
    { id: 'dashboard', path: 'dashboard', i18n: 'navigation.home', icon: 'house_outlined' },
    { id: 'players', path: 'players', i18n: 'navigation.player', icon: 'profile_rounded' },
    { id: 'assignment', path: 'assignments', i18n: 'navigation.assignment', icon: 'coach_assignments' },
    { id: 'drill', path: 'drills', i18n: 'navigation.drill', icon: 'coach_custom_drill' },
    { id: 'admin', path: 'admin', i18n: 'navigation.admin', icon: 'lock', visible: this.#auth.visibleToAdmin() },
  ]);
}
