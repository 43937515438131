<img class="cookie" src="assets/images/cookie-icon.svg" alt="Cookie icon" />
<div class="container" *transloco="let t">
  <div typography="title" variant="2" class="title">{{ t('cookies.title') }}</div>
  <div
    class="text"
    [innerHTML]="
      t('cookies.description', {
        cookie: '/cookies',
        profile: '/profile',
        privacy: '/privacy',
      })
    "></div>
  <div class="button-container">
    <coach-button
      [loading]="loading()"
      (click)="acceptCookiePolicy(true)"
      size="large"
      [text]="t('cookies.accept.all')" />
    <coach-button
      [loading]="loading()"
      (click)="acceptCookiePolicy(false)"
      size="large"
      variant="outlined"
      [text]="t('cookies.accept.essential')" />
  </div>
</div>
