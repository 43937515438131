<nav class="sidebar" role="navigation">
  @if (utility.navigation.isOverlay()) {
    @if (utility.navigation.toggled()) {
      <div class="focus-trap" cdkTrapFocus cdkTrapFocusAutoCapture>
        <div class="close-container">
          <coach-button (click)="utility.toggleNavigation()" variant="text" type="white" icon="x" />
        </div>
        <coach-tabs [tabs]="tabs()" orientation="vertical-row" (onClick)="utility.toggleNavigation()" />
        <img class="logo logo-text" src="assets/images/coach-logo-primary.svg" alt="Coach logo" />
      </div>
    }
  } @else {
    <coach-tabs [tabs]="tabs()" orientation="vertical-column" />
    <img class="logo logo-icon" src="assets/images/coach-logo.svg" alt="Coach logo" />
  }
</nav>
