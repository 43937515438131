import { provideTransloco } from '@jsverse/transloco';
import { provideTranslocoMessageformat } from '@jsverse/transloco-messageformat';
import { provideTranslocoPersistLang } from '@jsverse/transloco-persist-lang';
import { TranslocoHttpLoader } from '@shared/services/transloco.loader';

const availableLangs = ['en', 'sv', 'ja'];
const defaultLang = 'en';
const fallbackLang = 'en';

export function provideI18n(isDevMode?: boolean) {
  return [
    provideTransloco({
      config: {
        availableLangs,
        defaultLang,
        fallbackLang,
        reRenderOnLangChange: true,
        prodMode: !isDevMode,
        missingHandler: {
          useFallbackTranslation: true,
        },
      },
      loader: TranslocoHttpLoader,
    }),
    provideTranslocoPersistLang({
      storage: {
        useValue: localStorage,
      },
    }),
    provideTranslocoMessageformat(),
  ];
}
