import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '@shared/services/api.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'coach-login-callback',
  standalone: true,
  imports: [],
  templateUrl: './login-callback.component.html',
  styleUrl: './login-callback.component.scss',
})
export class LoginCallbackComponent implements OnInit {
  #auth = inject(AuthService);
  #router = inject(Router);
  #api = inject(ApiService);

  async ngOnInit() {
    const response = await this.#auth.signinRedirectCallback().catch(reason => {
      this.#router.navigate(['/login']);

      // Throw error so generic error handler can catch it and show a toast
      throw reason;
    });
    // @ts-expect-error: State is unknown until we have decided what it should be
    const redirectUrl = response?.state?.redirect || '/';
    this.#router.navigate([redirectUrl]);
  }
}
