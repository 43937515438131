<span class="hide-on-tablet">View as:</span>
@for (role of roles; track $index) {
  <coach-button
    class="button"
    [selected]="role === auth.viewAsRole()"
    (click)="viewAs(role)"
    [text]="role.toLocaleUpperCase()"
    size="small"
    variant="outlined" />
}
@if (auth.viewAsRole()) {
  <coach-button (click)="viewAs(null)" icon="x" size="small" variant="text" />
}
