import { APP_INITIALIZER } from '@angular/core';
import { AuthService } from '@auth/services/auth.service';

export function initializeApp(auth: AuthService): () => Promise<void> {
  return () => auth.initializeAuth();
}
export function provideAuth() {
  return {
    provide: APP_INITIALIZER,
    useFactory: initializeApp,
    multi: true,
    deps: [AuthService],
  };
}
