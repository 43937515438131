<h1 *transloco="let t; read: 'navigation'" class="placeholder-title">{{ t('home') }}</h1>

@if (!isProduction) {
  <div class="buttons">
    <coach-button (click)="toggleLoading()" text="Toggle Loading" />
    <coach-button (click)="toggleDisabled()" text="Toggle Disabled" />
  </div>

  <div class="buttons">
    @for (t of buttonTypes; track t) {
      <div class="sizes">
        @for (s of buttonSizes; track s) {
          <div class="variants">
            @for (v of buttonVariants; track v) {
              <coach-button
                [disabled]="disabled()"
                [loading]="loading()"
                [type]="t"
                [variant]="v"
                [text]="t + '/' + v"
                icon="coach_assignments"
                [size]="s" />
            }
            @for (v of buttonVariants; track v) {
              <coach-button
                [disabled]="disabled()"
                [loading]="loading()"
                [type]="t"
                [variant]="v"
                icon="coach_assignments"
                [size]="s" />
            }
          </div>
        }
      </div>
    }
  </div>
}
