import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { ApiService } from '@shared/services/api.service';
import { Role } from '@shared/types/generic.types';
import { AuthService } from '../services/auth.service';

export const inviteGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const auth = inject(AuthService);
  const api = inject(ApiService);

  if (auth.isLoggedIn()) {
    const invToken = route.paramMap.get('token');
    if (invToken?.length) {
      api.postViewModel(`users/invites/${invToken}`).subscribe(r => {
        const roles = auth.currentUser()?.profile?.['roles'] as Role[];

        switch (r.status) {
          case 'error':
            if (roles && roles.length) {
              router.navigate(['/dashboard'], { queryParams: { error: 'Invalid token' } });
              return false;
            } else {
              auth.logOut(state.url, { queryParams: { error: 'Invalid token' } });
              return false;
            }
          case 'loaded':
            router.navigate(['/dashboard']);
            return false;
          default:
            return false;
        }
      });
    }
    return false;
  } else {
    return true;
  }
};
