import { Component, inject } from '@angular/core';
import { DeviceService } from '@shared/services/device.service';
import { UtilityService } from '@shared/services/utility.service';
import { ActiveSessionButtonComponent } from '@shared/ui/active-session-button/active-session-button.component';
import { ButtonComponent } from '@shared/ui/button/button.component';
import { ProfileDropdownComponent } from '@shared/ui/profile-dropdown/profile-dropdown.component';
import { AuthService } from '@auth/services/auth.service';
import { RoleSwitcherComponent } from '../../auth/ui/role-switcher/role-switcher.component';

@Component({
  selector: 'coach-top-bar',
  standalone: true,
  imports: [ButtonComponent, ActiveSessionButtonComponent, ProfileDropdownComponent, RoleSwitcherComponent],
  templateUrl: './top-bar.component.html',
  styleUrl: './top-bar.component.scss',
  host: { '[class.full-screen]': 'utility.isFullScreen()' },
})
export class TopBarComponent {
  auth = inject(AuthService);
  device = inject(DeviceService);
  utility = inject(UtilityService);
}
