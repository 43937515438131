import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Role } from '@shared/types/generic.types';
import { ButtonComponent } from '@shared/ui/button/button.component';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'coach-role-switcher',
  standalone: true,
  imports: [ButtonComponent],
  templateUrl: './role-switcher.component.html',
  styleUrl: './role-switcher.component.scss',
})
export class RoleSwitcherComponent {
  auth = inject(AuthService);
  #router = inject(Router);

  roles: Role[] = ['admin', 'coach', 'player'];

  viewAs(role: Role | null) {
    localStorage.setItem('viewAs', role ?? '');
    this.auth.viewAsRole.set(role);
    this.#router.navigate(['/']);
  }
}
