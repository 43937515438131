import { Component, signal } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { ButtonComponent, ButtonSize, ButtonType, ButtonVariant } from '@shared/ui/button/button.component';
import { environment } from '@env/environment';

@Component({
  selector: 'coach-dashboard',
  standalone: true,
  host: { class: 'content-wrapper' },
  imports: [TranslocoDirective, ButtonComponent],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss',
})
export class DashboardComponent {
  isProduction = environment.production;

  buttonTypes: ButtonType[] = ['primary', 'info', 'positive', 'negative', 'warning', 'black', 'white'];
  buttonVariants: ButtonVariant[] = ['contained', 'outlined', 'text'];
  buttonSizes: ButtonSize[] = ['small', 'medium', 'large'];

  loading = signal<boolean>(false);
  disabled = signal<boolean>(false);

  toggleLoading() {
    this.loading.update(loading => !loading);
  }

  toggleDisabled() {
    this.disabled.update(disabled => !disabled);
  }
}
