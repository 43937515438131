<div class="login-container" *transloco="let t">
  <div class="login">
    <img class="logo" src="assets/images/coach-logo-horizontal-primary.svg" alt="logo" />
    <div class="form">
      <h1 class="title">{{ t('login.title') }}</h1>
      <coach-button (click)="logIn()" size="large" [text]="t('auth.login')" icon="arrow_right" iconPosition="right" />
      <div class="footer">
        <span>{{ t('login.footer') }}</span>
        <coach-button
          class="learn-more"
          variant="text"
          [text]="t('login.learnMore')"
          link="https://toptracer.com/coach"
          icon="external_link"
          iconPosition="right" />
      </div>
    </div>
    <coach-language-picker class="picker" [menuConfig]="{ anchorPoint: ['top', 'left'], animation: 'from-bottom' }" />
  </div>
  <div class="image-container">
    <img class="image" src="assets/images/login-image-1.jpg" alt="login-image" />
    <img class="image" src="assets/images/login-image-2.jpg" alt="login-image" />
  </div>
</div>
