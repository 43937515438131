import { HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from '../services/auth.service';

export function authInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn) {
  const authToken = inject(AuthService).currentUser()?.access_token;

  // Only add the Authorization header to URLs that match this pattern
  const endpoints = /^(http|https):\/\/(dev|test)\.game-data\.toptracer\.com\//;

  if (!authToken || !endpoints.test(req.url)) {
    return next(req);
  }

  return next(req.clone({ setHeaders: { Authorization: `Bearer ${authToken}` } }));
}
