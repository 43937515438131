import { APP_INITIALIZER, inject } from "@angular/core";
import { FeatureFlagResponse, FeatureFlagService } from "@shared/services/feature-flag.service";
import { Observable } from "rxjs";

function initializeFeatureFlag(): () => Observable<FeatureFlagResponse> {
  const featureFlagService = inject(FeatureFlagService);
  return () => featureFlagService.getFeatureFlags();
}

export const provideFeatureFlag = () => ({
  provide: APP_INITIALIZER,
  useFactory: initializeFeatureFlag,
  deps: [],
  multi: true,
});