import { Component, effect, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoDirective } from '@jsverse/transloco';
import { ButtonComponent } from '@shared/ui/button/button.component';
import { LanguagePickerComponent } from '@shared/ui/language-picker/language-picker.component';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'coach-login',
  standalone: true,
  imports: [TranslocoDirective, ButtonComponent, LanguagePickerComponent],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent {
  #auth = inject(AuthService);
  #router = inject(Router);
  #route = inject(ActivatedRoute);

  returnUrl = this.#route.snapshot.queryParams['redirect'] || '/';

  constructor() {
    effect(() => {
      if (this.#auth.isLoggedIn()) {
        this.#router.navigate([this.returnUrl]);
      }
    });
  }

  logIn() {
    this.#auth.logIn(this.returnUrl);
  }
}
