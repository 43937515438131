<div class="menu">
  @if (auth.visibleToCoach()) {
    @if (utility.navigation.isOverlay()) {
      <coach-button (click)="utility.toggleNavigation()" variant="text" icon="menu" />
    }
  } @else {
    <img class="coach-logo" src="assets/images/coach-logo.svg" alt="Coach logo" />
  }
  @if (!auth.isProduction) {
    <div class="hide-on-mobile">
      <coach-role-switcher />
    </div>
  }
</div>
@if (utility.isFullScreen()) {
  <img
    class="logo hide-on-mobile"
    src="assets/images/coach-logo-horizontal-primary-no-shield.svg"
    alt="Coach text logo" />
}

<div class="content">
  @if (auth.visibleToCoach()) {
    <coach-active-session-button />
  }
  <coach-profile-dropdown />
</div>
