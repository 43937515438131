<!-- As we are reusing the core spinner, the styles are defined in _spinner.scss -->
<div class="coach-spinner">
  <div class="wrapper">
    <div class="ball"></div>
    <div class="ball"></div>
    <div class="ball"></div>
    <div class="ball"></div>
    <div class="ball"></div>
  </div>
  <div>
    <h3>Loading Coach</h3>
  </div>
</div>
